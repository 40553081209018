var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// https://d3js.org/d3-queue/ Version 3.0.7. Copyright 2017 Mike Bostock.
(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var slice = [].slice;
  var noabort = {};

  function Queue(size) {
    (this || _global)._size = size;
    (this || _global)._call = (this || _global)._error = null;
    (this || _global)._tasks = [];
    (this || _global)._data = [];
    (this || _global)._waiting = (this || _global)._active = (this || _global)._ended = (this || _global)._start = 0; // inside a synchronous task callback?
  }

  Queue.prototype = queue.prototype = {
    constructor: Queue,
    defer: function (callback) {
      if (typeof callback !== "function") throw new Error("invalid callback");
      if ((this || _global)._call) throw new Error("defer after await");
      if ((this || _global)._error != null) return this || _global;
      var t = slice.call(arguments, 1);
      t.push(callback);
      ++(this || _global)._waiting, (this || _global)._tasks.push(t);
      poke(this || _global);
      return this || _global;
    },
    abort: function () {
      if ((this || _global)._error == null) abort(this || _global, new Error("abort"));
      return this || _global;
    },
    await: function (callback) {
      if (typeof callback !== "function") throw new Error("invalid callback");
      if ((this || _global)._call) throw new Error("multiple await");

      (this || _global)._call = function (error, results) {
        callback.apply(null, [error].concat(results));
      };

      maybeNotify(this || _global);
      return this || _global;
    },
    awaitAll: function (callback) {
      if (typeof callback !== "function") throw new Error("invalid callback");
      if ((this || _global)._call) throw new Error("multiple await");
      (this || _global)._call = callback;
      maybeNotify(this || _global);
      return this || _global;
    }
  };

  function poke(q) {
    if (!q._start) {
      try {
        start(q);
      } // let the current task complete
      catch (e) {
        if (q._tasks[q._ended + q._active - 1]) abort(q, e); // task errored synchronously
        else if (!q._data) throw e; // await callback errored synchronously
      }
    }
  }

  function start(q) {
    while (q._start = q._waiting && q._active < q._size) {
      var i = q._ended + q._active,
          t = q._tasks[i],
          j = t.length - 1,
          c = t[j];
      t[j] = end(q, i);
      --q._waiting, ++q._active;
      t = c.apply(null, t);
      if (!q._tasks[i]) continue; // task finished synchronously

      q._tasks[i] = t || noabort;
    }
  }

  function end(q, i) {
    return function (e, r) {
      if (!q._tasks[i]) return; // ignore multiple callbacks

      --q._active, ++q._ended;
      q._tasks[i] = null;
      if (q._error != null) return; // ignore secondary errors

      if (e != null) {
        abort(q, e);
      } else {
        q._data[i] = r;
        if (q._waiting) poke(q);else maybeNotify(q);
      }
    };
  }

  function abort(q, e) {
    var i = q._tasks.length,
        t;
    q._error = e; // ignore active callbacks

    q._data = undefined; // allow gc

    q._waiting = NaN; // prevent starting

    while (--i >= 0) {
      if (t = q._tasks[i]) {
        q._tasks[i] = null;

        if (t.abort) {
          try {
            t.abort();
          } catch (e) {
            /* ignore */
          }
        }
      }
    }

    q._active = NaN; // allow notification

    maybeNotify(q);
  }

  function maybeNotify(q) {
    if (!q._active && q._call) {
      var d = q._data;
      q._data = undefined; // allow gc

      q._call(q._error, d);
    }
  }

  function queue(concurrency) {
    if (concurrency == null) concurrency = Infinity;else if (!((concurrency = +concurrency) >= 1)) throw new Error("invalid concurrency");
    return new Queue(concurrency);
  }

  exports.queue = queue;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const queue = exports.queue,
      __esModule = exports.__esModule;